<template>
  <div class="row justify-content-center">
    <div
      class="card custom-card gutter-b p-5"
      style="height: 90%; border-radius: 15px"
    >
      <div class="row px-2">
        <div class="col-6 card custom-card gutter-b mt-2 p-5">
          <p>
            Menurut Bapak/Ibu bermanfaatkah kegiatan Peningkatan Kinerja PTSP
            dan Kinerja PPB Pemerintah Daerah ini?
          </p>
        </div>
        <div
          class="col-6 card custom-card gutter-b mt-2 p-5"
          style="background-color: #ebedf3"
        >
          <b-form-radio-group
            v-model="jawaban_a1"
            :options="options_a1"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-radio-group>
        </div>
      </div>
      <div class="row px-2">
        <div class="col-6 card custom-card gutter-b mt-2 p-5">
          <p>
            Menurut Bapak/Ibu bagaimana tempat dan fasilitas dalam kegiatan
            Peningkatan Kinerja PTSP dan Kinerja PPB Pemerintah Daerah yang ada?
          </p>
        </div>
        <div
          class="col-6 card custom-card gutter-b mt-2 p-5"
          style="background-color: #ebedf3"
        >
          <b-form-radio-group
            v-model="jawaban_a2"
            :options="options_a2"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-radio-group>
        </div>
      </div>
      <div class="row px-2">
        <div class="col-6 card custom-card gutter-b mt-2 p-5">
          <p>
            Menurut Bapak/Ibu bagaimana kesiapan panitia dalam melaksanakan
            kegiatan Peningkatan Kinerja PTSP dan Kinerja PPB Pemerintah Daerah?
          </p>
        </div>
        <div
          class="col-6 card custom-card gutter-b mt-2 p-5"
          style="background-color: #ebedf3"
        >
          <b-form-radio-group
            v-model="jawaban_a3"
            :options="options_a3"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-radio-group>
        </div>
      </div>

      <div class="row px-2">
        <div class="col-6 card custom-card gutter-b mt-2 p-5">
          <p>
            Menurut Bapak/Ibu bagaimana kesesuaian materi yang disampaikan dalam
            Peningkatan Kinerja PTSP dan Kinerja PPB Pemerintah Daerah dengan
            tugas dan fungsi pokok Bapak/Ibu?
          </p>
        </div>
        <div
          class="col-6 card custom-card gutter-b mt-2 p-5"
          style="background-color: #ebedf3"
        >
          <b-form-radio-group
            v-model="jawaban_b1"
            :options="options_b1"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-radio-group>
        </div>
      </div>
      <div class="row px-2">
        <div class="col-6 card custom-card gutter-b mt-2 p-5">
          <p>
            Menurut Bapak/Ibu bagaimana kelengkapan materi yang disampaikan
            dalam Peningkatan Kinerja PTSP Peningkatan Kinerja PTSP dan Kinerja
            PPB Pemerintah Daerah?
          </p>
        </div>
        <div
          class="col-6 card custom-card gutter-b mt-2 p-5"
          style="background-color: #ebedf3"
        >
          <b-form-radio-group
            v-model="jawaban_b2"
            :options="options_b2"
            class="mb-3"
            value-field="item"
            text-field="name"
            disabled-field="notEnabled"
          ></b-form-radio-group>
        </div>
      </div>

      <div class="px-2 float-right">
        <button
          type="button"
          @click="generateCert"
          style="float: right"
          class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
        >
          <i class="fas fa-save"></i> Kirim
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
  name: "Survey",
  data() {
    return {
      url: localStorage.getItem("baseapi"),
      token: localStorage.getItem("id_token"),
      user: JSON.parse(localStorage.getItem("user")),
      id_peserta: "",
      id_kegiatan: "", // Assuming id_kegiatan is fixed for this example
      jawaban_a1: null,
      jawaban_a2: null,
      jawaban_a3: null,

      jawaban_b1: null,
      jawaban_b2: null,

      jawaban_c1: null,
      jawaban_c2: null,
      jawaban_c3: null,
      jawaban_c4: null,
      jawaban_c5: null,
      jawaban_c6: null,
      jawaban_c7: null,

      saran: "",

      options_a1: [
        { item: "Sangat bermanfaat", name: "Sangat bermanfaat" },
        { item: "Bermanfaat", name: "Bermanfaat" },
        { item: "Cukup Bermanfaat", name: "Cukup Bermanfaat" },
        { item: "Tidak Bermanfaat", name: "Tidak Bermanfaat" },
        { item: "Sangat tidak bermanfaat", name: "Sangat tidak bermanfaat" },
      ],
      options_a2: [
        { item: "Sangat baik dan lengkap", name: "Sangat baik dan lengkap" },
        { item: "Baik dan lengkap", name: "Baik dan lengkap" },
        { item: "Cukup baik dan lengkap", name: "Cukup baik dan lengkap" },
        {
          item: "Tidak baik dan tidak lengkap",
          name: "Tidak baik dan tidak lengkap",
        },
        {
          item: "Sangat tidak baik dan sangat tidak lengkap",
          name: "Sangat tidak baik dan sangat tidak lengkap",
        },
      ],
      options_a3: [
        { item: "Sangat siap", name: "Sangat siap" },
        { item: "Siap", name: "Siap" },
        { item: "Cukup Siap", name: "Cukup Siap" },
        {
          item: "Tidak Siap",
          name: "Tidak Siap",
        },
        {
          item: "Sangat tidak Siap",
          name: "Sangat tidak Siap",
        },
      ],

      options_b1: [
        { item: "Sangat sesuai", name: "Sangat sesuai" },
        { item: "Sesuai", name: "Sesuai" },
        { item: "Cukup Sesuai", name: "Cukup Sesuai" },
        {
          item: "Tidak Sesuai",
          name: "Tidak Sesuai",
        },
        {
          item: "Sangat tidak sesuai",
          name: "Sangat tidak sesuai",
        },
      ],
      options_b2: [
        { item: "Sangat lengkap", name: "Sangat lengkap" },
        { item: "Lengkap", name: "Lengkap" },
        { item: "Cukup lengkap", name: "Cukup lengkap" },
        {
          item: "Tidak lengkap",
          name: "Tidak lengkap",
        },
        {
          item: "Sangat tidak lengap",
          name: "Sangat tidak lengap",
        },
      ],

      options_c1: [
        { item: "Sangat menguasai", name: "Sangat menguasai" },
        { item: "Menguasai", name: "Menguasai" },
        { item: "Cukup menguasai", name: "Cukup menguasai" },
        {
          item: "Tidak menguasai",
          name: "Tidak menguasai",
        },
        {
          item: "Sangat tidak menguasai",
          name: "Sangat tidak menguasai",
        },
      ],
      options_c2: [
        { item: "Sangat menarik", name: "Sangat menarik" },
        { item: "Menarik", name: "Menarik" },
        { item: "Cukup menarik", name: "Cukup menarik" },
        {
          item: "Tidak menarik",
          name: "Tidak menarik",
        },
        {
          item: "Sangat tidak menarik",
          name: "Sangat tidak menarik",
        },
      ],
      options_c3: [
        { item: "Sangat menarik", name: "Sangat menarik" },
        { item: "Menarik", name: "Menarik" },
        { item: "Cukup menarik", name: "Cukup menarik" },
        {
          item: "Tidak menarik",
          name: "Tidak menarik",
        },
        {
          item: "Sangat tidak menarik",
          name: "Sangat tidak menarik",
        },
      ],
      options_c4: [
        { item: "Sangat menarik", name: "Sangat menarik" },
        { item: "Menarik", name: "Menarik" },
        { item: "Cukup menarik", name: "Cukup menarik" },
        {
          item: "Tidak menarik",
          name: "Tidak menarik",
        },
        {
          item: "Sangat tidak menarik",
          name: "Sangat tidak menarik",
        },
      ],
      options_c5: [
        { item: "Sangat menarik", name: "Sangat menarik" },
        { item: "Menarik", name: "Menarik" },
        { item: "Cukup menarik", name: "Cukup menarik" },
        {
          item: "Tidak menarik",
          name: "Tidak menarik",
        },
        {
          item: "Sangat tidak menarik",
          name: "Sangat tidak menarik",
        },
      ],
      options_c6: [
        { item: "Sangat menarik", name: "Sangat menarik" },
        { item: "Menarik", name: "Menarik" },
        { item: "Cukup menarik", name: "Cukup menarik" },
        {
          item: "Tidak menarik",
          name: "Tidak menarik",
        },
        {
          item: "Sangat tidak menarik",
          name: "Sangat tidak menarik",
        },
      ],
      options_c7: [
        { item: "Sangat jelas", name: "Sangat jelas" },
        { item: "Jelas", name: "Jelas" },
        { item: "Cukup jelas", name: "Cukup jelas" },
        {
          item: "Tidak jelas",
          name: "Tidak jelas",
        },
        {
          item: "Sangat tidak jelas",
          name: "Sangat tidak jelas",
        },
      ],
    };
  },
  methods: {
    loadData() {
      this.axios
        .get(this.url + "/jadwal_peserta?filter=id_user,=," + this.user.id, {
          headers: {
            xth: this.token,
          },
        })
        .then((response) => {
          if (response.data.data && response.data.data.length > 0) {
            this.id_peserta = response.data.data[0].id;
            this.id_kegiatan = response.data.data[0].id_kegiatan;
          } else {
            Swal.fire({
              title: "Gagal!",
              text: "Peserta tidak ditemukan",
              icon: "error",
            });
          }
        })
        .catch((e) => {
          Swal.fire({
            title: "Gagal!",
            text: "Terjadi kesalahan saat memuat data",
            icon: "error",
          });
        });
    },

    generateCert() {
      if (!this.jawaban_1 || !this.jawaban_2 || !this.jawaban_3) {
        Swal.fire({
          title: "Gagal!",
          text: "Harap mengisi semua pertanyaan survei",
          icon: "error",
        });
        return;
      }

      Swal.fire({
        title: "Kirim Survey",
        text: "Apakah Anda yakin ingin mengirim survey?",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ya, kirim!",
        cancelButtonText: "Batal",
      }).then((result) => {
        if (result.isConfirmed) {
          const surveyData = {
            id_peserta: this.id_peserta,
            id_kegiatan: this.id_kegiatan,
            jawaban_1: this.jawaban_1,
            jawaban_2: this.jawaban_2,
            jawaban_3: this.jawaban_3,
          };

          this.axios
            .post(this.url + "/survey_bimtek", surveyData, {
              headers: {
                xth: this.token,
              },
            })
            .then((response) => {
              return this.axios.get(
                this.url +
                  "/jadwal_peserta/generate_sertifikat/" +
                  this.id_peserta,
                {
                  headers: {
                    xth: this.token,
                  },
                }
              );
            })
            .then((response) => {
              Swal.fire({
                title: "Berhasil mengirim survey",
                text: "Sertifikat dapat diunduh di halaman utama",
                icon: "success",
              });
              this.loadData();
              this.$router.push("/dashboard");
            })
            .catch((e) => {
              Swal.fire({
                title: "Gagal!",
                text: "Terjadi kesalahan",
                icon: "error",
              });
            });
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Survey" }]);
    this.loadData();
  },
};
</script>
